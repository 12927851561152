import React, { useState, useEffect, useContext } from "react";
import Message from "../components/UI/Message/Message";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import RequestService from "../api/RequestService";
import { useNavigate, useParams } from "react-router-dom";
import { useFetching } from "../hooks/useFetching";
import { Icon, Textarea, Button } from "react-materialize";
import { parseForm, getQueryParam } from "../utils/service";
import { AuthContext } from "../context";
import CircleLoader from "../components/UI/CircleLoader/CircleLoader";
import noAvatar from "../images/no-avatar.png";
import Verified from "../components/UI/Verified/Verified";
import useConfirm from "../context/ConfirmDialog";

const Chat = ({}) => {
  const [messages, setMessages] = useState([]);
  const [messagesPage, setMessagesPage] = useState(1);
  const params = useParams();
  const [message, setMessage] = useState("");
  const [userData, setUserData] = useState(null);
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const navigate = useNavigate();
  const confirm = useConfirm();

  const getChatMessages = async (page = 1) => {
    const response = (await RequestService.getChatMessages(params.id, page))
      .data;
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }
    response.result.elements.sort((a, b) => b.num - a.num);
    setMessages(response.result.elements);
  };

  const [fetchMessages, isMessagesLoading, messagesError] =
    useFetching(getChatMessages);

  useEffect(() => {
    // console.log(projectPage);
    fetchMessages(messagesPage);
  }, [messagesPage]);

  useEffect(() => {
    fetchUserData();

    let prefilledText = getQueryParam("text");
    if (prefilledText) {
      setMessage(decodeURIComponent(prefilledText));
    }
  }, []);

  useEffect(() => {
    let chatPlace = document.getElementById("chat-place");
    if (chatPlace) {
      chatPlace.scrollTop = chatPlace.scrollHeight;
    }
  }, [messages]);

  const sendMessage = async (e) => {
    e.preventDefault();

    setFullScreenLoading(true);
    let htmlForm = e.currentTarget;
    let sendFormData = parseForm(htmlForm);
    // for (let pair of sendFormData.entries()) {
    //   console.log(pair);
    // }
    const response = (
      await RequestService.sendForm(sendFormData, "/messages/send")
    ).data;
    // console.log(response);
    setFullScreenLoading(false);

    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    setMessage("");
    fetchMessages();
  };

  const getUser = async () => {
    const response = (await RequestService.getUser(params.id)).data;
    // console.log(response);
    if (!response.response_code) {
      return;
    }
    setUserData(response.result);
  };

  const [fetchUserData, isUserDataLoading, isUserDataError] =
    useFetching(getUser);

  return (
    <RegularPage isDataExist={messages} backText={"Назад"}>
      <div className="row">
        {isUserDataLoading || !userData ? (
          <div className="col s12 center-align">
            <CircleLoader />
          </div>
        ) : (
          <div>
            {!userData ? (
              <div className="col s12 center-align">
                Данные о собеседнике не получены
              </div>
            ) : (
              <div className="col s12 center-align l500">
                <div className="row nm">
                  <div
                    className="col s12 mb15"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      className="circular-image pastel-shadow"
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <div
                        className={
                          userData.avatar_img_url
                            ? "circular-image hand-cursor"
                            : "circular-image image-theme-filter hand-cursor"
                        }
                        onClick={() => {
                          navigate("/users/" + params.id);
                        }}
                        style={{
                          backgroundImage:
                            "url(" +
                            (userData.avatar_img_url
                              ? userData.avatar_img_url
                              : noAvatar) +
                            ")",
                          width: "100px",
                          height: "100px",
                        }}
                      ></div>
                    </div>
                    <Verified
                      ml={-25}
                      mt={-3}
                      isOriginal={userData.is_original}
                      isVerified={userData.is_verified}
                    />
                  </div>
                  <div className="col s12">
                    <span
                      className="hand-cursor"
                      onClick={() => {
                        navigate("/users/" + params.id);
                      }}
                    >
                      {userData.first_name + " " + userData.last_name}{" "}
                      <i
                        className="material-icons"
                        style={{ position: "absolute", marginTop: "4px" }}
                      >
                        navigate_next
                      </i>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {isMessagesLoading ? (
        <div className="row nm">
          <CircleLoader />
        </div>
      ) : (
        <div>
          <div className="row nm">
            {!messages || messages.length === 0 ? (
              <div className="col s12 center-align">Сообщений нет</div>
            ) : (
              <div className="col s12">
                <div className="card bg">
                  <div
                    id="chat-place"
                    className="card-content"
                    style={{
                      height: "400px",
                      overflowY: "scroll",
                    }}
                  >
                    {messages.map((message) => (
                      <Message
                        isOwner={message.is_owner}
                        name={
                          message.is_owner
                            ? message.from_user_name
                            : message.to_user_name
                        }
                        text={message.text}
                        datetime={message.timestamp}
                        key={message.num + "-" + message.timestamp}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          <form onSubmit={sendMessage}>
            <div className="row nm">
              <Textarea
                id="message-text"
                name="message-text"
                placeholder={"Сообщение..."}
                s={9}
                l={11}
                style={{ marginBottom: "0px" }}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                value={message}
              />
              <input type="hidden" name="message-to-user" value={params.id} />
              <div className="col s3 l1 center-align mt20">
                <Button
                  flat
                  className="iq-btn col s12"
                  type="submit"
                  icon={<Icon>send</Icon>}
                ></Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </RegularPage>
  );
};

export default Chat;
