import React from "react";
import { useState, useEffect, useContext } from "react";
import {
  TextInput,
  Button,
  Select,
  Icon,
  Textarea,
  Checkbox,
  DatePicker,
  TimePicker,
} from "react-materialize";
import M from "materialize-css";
import { useNavigate, useSearchParams } from "react-router-dom";
import RequestService from "../api/RequestService";
import { parseForm } from "../utils/service";
import CircleLoader from "../components/UI/CircleLoader/CircleLoader";
import { AuthContext } from "../context";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import moment from "moment";
import useConfirm from "../context/ConfirmDialog";

const CreateEditPackage = ({ mode = "create" }) => {
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const [objectOptions, setObjectOptions] = useState([]);
  const [objects, setObjects] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [packageName, setPackageName] = useState("");
  const [packagePrice, setPackagePrice] = useState("");
  const [packageDescription, setPackageDescription] = useState("");
  const [packageCurrency, setPackageCurrency] = useState("");
  const [packagePaymentType, setPackagePaymentType] = useState("");
  const [packagePspMode, setPackagePspMode] = useState("");
  const [packageQuantity, setPackageQuantity] = useState("");
  const [packageParticipationLink, setPackageParticipationLink] = useState("");

  const [ageLimitOptions, setAgeLimitOptions] = useState(null);
  const [eventAgeLimit, setEventAgeLimit] = useState("");

  const [packageData, setPackageData] = useState(null);
  const navigate = useNavigate();
  const confirm = useConfirm();
  const objectId = searchParams.get("event")
    ? searchParams.get("event")
    : searchParams.get("project")
    ? searchParams.get("project")
    : null;

  const [hasEventFields, setHasEventFields] = useState(0);

  useEffect(() => {
    // console.log(searchParams.get("event"));
    setFullScreenLoading(true);
    getUserEvents();
    getCurrencies();
    getAgeLimits();
  }, []);

  useEffect(() => {
    // console.log(objectId);
    checkEventFields(objectId);
  }, [objects]);

  const getUserEvents = async () => {
    let response = (await RequestService.getUserEvents()).data;
    if (!response.response_code) {
      console.error(response);
      return;
    }
    let allEventsData = response.result;
    // setEvents(allEventsData);
    // console.log(allEventsData);
    let eventOptions = [];
    allEventsData.forEach((event) => {
      eventOptions.push(
        <option value={event.id} key={event.id}>
          {event.name}
        </option>
      );
    });

    response = (await RequestService.getUserProjects()).data;
    if (!response.response_code) {
      console.error(response);
      return;
    }
    let allProjectsData = response.result;
    // console.log(allProjectsData);
    let projectOptions = [];
    allProjectsData.forEach((project) => {
      projectOptions.push(
        <option value={project.id} key={project.id}>
          {project.name}
        </option>
      );
    });

    setObjectOptions([...eventOptions, ...projectOptions]);
    setObjects([...allEventsData, ...allProjectsData]);
  };

  const getAgeLimits = async () => {
    let ageLimitResponse = (await RequestService.getAgeLimits()).data;
    if (!ageLimitResponse.response_code) {
      console.error(ageLimitResponse);
      return;
    }
    let ageLimitsData = ageLimitResponse.result;
    // console.log(ageLimitsData);
    let limitOptions = [];
    ageLimitsData.forEach((limit) => {
      limitOptions.push(
        <option
          value={limit.id}
          key={limit.id + "-" + (Math.random() * 10000000).toFixed(0)}
        >
          {limit.age + "+"}
        </option>
      );
    });

    setAgeLimitOptions(limitOptions);

    // await getPackage();
    // setFullScreenLoading(false);
  };

  const getPackage = async (packageId) => {
    let response = (await RequestService.getPackage(packageId)).data;
    if (!response.response_code) {
      console.error(response);
      return;
    }
    // console.log(response.result);

    setPackageName(response.result.name);
    setPackageQuantity(
      response.result.tickets_quantity !== null
        ? response.result.tickets_quantity
        : ""
    );
    setPackagePrice(Number(response.result.price));
    setPackageDescription(
      response.result.description ? response.result.description : ""
    );
    setPackageCurrency(String(response.result.currency_id));
    setPackagePaymentType(response.result.is_online_payment_ym);
    setPackagePspMode(response.result.is_psp_mode);
    setPackageParticipationLink(
      response.result.participation_link !== null
        ? response.result.participation_link
        : ""
    );
    if (response.result.age_limit_id) {
      setEventAgeLimit(String(response.result.age_limit_id));
    }

    setPackageData(response.result);
  };

  const getCurrencies = async () => {
    let response = (await RequestService.getCurrencies()).data;
    if (!response.response_code) {
      console.error(response);
      return;
    }
    let currenciesData = response.result;
    // console.log(currenciesData);
    // setEvents(allEventsData);
    let currencyOptions = [];
    currenciesData.forEach((currency) => {
      currencyOptions.push(
        <option value={currency.id} key={currency.id}>
          {(currency.symbol ? currency.symbol : "") +
            " (" +
            currency.code +
            ")" +
            " " +
            currency.name}
        </option>
      );
    });

    setCurrencyOptions(currencyOptions);

    let packageId = searchParams.get("package");
    if (packageId) {
      await getPackage(packageId);
    }
    setFullScreenLoading(false);
  };

  const createPackageDraft = async (e) => {
    e.preventDefault();

    setFullScreenLoading(true);
    let htmlForm = e.currentTarget;
    // console.log(htmlForm);

    let sendFormData = parseForm(htmlForm);
    // for (let pair of sendFormData.entries()) {
    //   console.log(pair);
    // }
    const response = (
      await RequestService.sendForm(sendFormData, "/packages/create")
    ).data;
    // console.log(response);
    setFullScreenLoading(false);

    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    await confirm({
      description: "Пакет участия успешно создан",
      isAlert: true,
    });
    // alert("Пакет участия успешно создан");
    let eventId = searchParams.get("event");
    if (eventId) {
      navigate("/events/" + eventId);
    }
    let projectId = searchParams.get("project");
    if (projectId) {
      navigate("/projects/" + projectId);
    }
  };

  const updatePackage = async (e) => {
    e.preventDefault();

    setFullScreenLoading(true);
    let htmlForm = e.currentTarget;
    // console.log(htmlForm);

    let sendFormData = parseForm(htmlForm);
    // for (let pair of sendFormData.entries()) {
    //   console.log(pair);
    // }
    sendFormData.append("package-id", searchParams.get("package"));
    const response = (
      await RequestService.sendForm(sendFormData, "/packages/update")
    ).data;
    // console.log(response);
    setFullScreenLoading(false);

    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    await confirm({
      description: "Изменения сохранены",
      isAlert: true,
    });
    // alert("Изменения сохранены");
    let eventId = searchParams.get("event");
    if (eventId) {
      navigate("/events/" + eventId);
    }
    let projectId = searchParams.get("project");
    if (projectId) {
      navigate("/projects/" + projectId);
    }
  };

  const checkEventFields = (oid) => {
    let targetObject = null;
    objects.forEach((obj) => {
      if (obj.id == oid) {
        targetObject = obj;
      }
    });

    if (!targetObject) {
      return false;
    }

    if (targetObject.hasOwnProperty("money_required")) {
      setHasEventFields(0);
    } else {
      setHasEventFields(1);
    }
  };

  // useEffect(() => {
  //   console.log(packagePaymentType);
  // }, [packagePaymentType]);

  return !isFullScreenLoading ? (
    <RegularPage
      isDataExist={1}
      backText={"Назад"}
      header={
        mode === "edit"
          ? searchParams.get("event")
            ? "Редактирование встречи"
            : "Редактирование варианта участия"
          : searchParams.get("event")
          ? "Новая встреча"
          : "Новый вариант участия"
      }
    >
      <form
        id="new-package"
        onSubmit={mode === "edit" ? updatePackage : createPackageDraft}
      >
        <div className="row">
          <Select
            id="package-object-id"
            name="package-object-id"
            s={12}
            icon={<Icon>checklist</Icon>}
            label={"Мероприятие или проект"}
            options={{
              classes: "",
              dropdownOptions: {
                alignment: "left",
                closeOnClick: true,
                constrainWidth: true,
              },
            }}
            value={objectId}
            onChange={(e) => {
              // console.log(e.target.value);
              checkEventFields(e.target.value);
            }}
            disabled={mode == "edit" ? true : false}
          >
            {objectOptions}
          </Select>

          <TextInput
            id="package-name"
            name="package-name"
            icon="inventory_2"
            s={12}
            l={hasEventFields ? 8 : 12}
            label="Название"
            placeholder="«Стартовый»"
            value={packageName}
            onChange={(e) => {
              setPackageName(e.target.value);
            }}
            required
          />

          {hasEventFields ? (
            <div>
              <TextInput
                id="package-tickets-quantity"
                name="package-tickets-quantity"
                icon="confirmation_number"
                s={12}
                l={4}
                label="Количество мест"
                placeholder="Необязательно"
                inputMode="decimal"
                value={String(packageQuantity)}
                onChange={(e) => {
                  setPackageQuantity(e.target.value);
                }}
              />

              <div className="col s12 mb25">
                Помните, что дата и время устанавливаются в соответствии с
                часовым поясом события. Часовой пояс мероприятия определяется
                либо автоматически по координатам места события, либо он
                устанавливается вручную (чаще для онлайн-событий) в настройках
                этого события. Ниже для встречи вам нужно указать дату и время
                уже с учетом часового пояса.
              </div>

              <DatePicker
                id="package-date"
                name="package-date"
                label={"Дата"}
                icon="event"
                placeholder={"10.03.2035"}
                s={12}
                l={3}
                options={{
                  autoClose: true,
                  // container: null,
                  //   defaultDate: moment(new Date()).format("DD.MM.YYYY"),
                  defaultDate:
                    packageData && packageData.datetime
                      ? moment(packageData.datetime).toDate()
                      : "",
                  // disableDayFn: null,
                  // disableWeekends: false,
                  // events: [],
                  firstDay: 1,
                  format: "dd.mm.yyyy",
                  i18n: {
                    cancel: "Отмена",
                    clear: "Очистить",
                    done: "Выбрать",
                    months: [
                      "Январь",
                      "Февраль",
                      "Март",
                      "Апрель",
                      "Май",
                      "Июнь",
                      "Июль",
                      "Август",
                      "Сентябрь",
                      "Октябрь",
                      "Ноябрь",
                      "Декабрь",
                    ],
                    monthsShort: [
                      "Января",
                      "Февраля",
                      "Марта",
                      "Апреля",
                      "Мая",
                      "Июня",
                      "Июля",
                      "Августа",
                      "Сентября",
                      "Октября",
                      "Ноября",
                      "Декабря",
                    ],
                    nextMonth: "›",
                    previousMonth: "‹",
                    weekdays: [
                      "Воскресенье",
                      "Понедельник",
                      "Вторник",
                      "Среда",
                      "Четверг",
                      "Пятница",
                      "Суббота",
                    ],
                    weekdaysAbbrev: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
                    weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
                  },
                  // isRTL: false,
                  // maxDate: null,
                  // minDate: null,
                  // onClose: null,
                  // onDraw: null,
                  // onOpen: null,
                  // onSelect: null,
                  // parse: null,
                  setDefaultDate: true,
                  // showClearBtn: false,
                  // showDaysInNextAndPreviousMonths: true,
                  // showMonthAfterYear: false,
                  // yearRange: 10,
                }}
              />
              <TimePicker
                id="package-time"
                name="package-time"
                label={"Время"}
                icon={"schedule"}
                placeholder={"18:00"}
                s={12}
                l={3}
                defaultValue={
                  packageData && packageData.datetime
                    ? moment(packageData.datetime).format("HH:mm")
                    : ""
                }
                options={{
                  autoClose: true,
                  i18n: {
                    cancel: "Отмена",
                    clear: "Очистить",
                    done: "Выбрать",
                  },
                  showClearBtn: false,
                  twelveHour: false,
                  vibrate: true,
                }}
              />

              <TextInput
                id="package-duration"
                name="package-duration"
                icon={<Icon>av_timer</Icon>}
                s={12}
                l={3}
                label="Длительность (минут)"
                placeholder="120"
                inputMode="decimal"
                defaultValue={
                  packageData && packageData.duration
                    ? String(packageData.duration)
                    : null
                }
              />

              <Select
                id="package-age-limit"
                name="package-age-limit"
                s={12}
                l={3}
                icon={<Icon>spa</Icon>}
                label={"Возрастной ценз"}
                options={{
                  classes: "",
                  dropdownOptions: {
                    alignment: "left",
                    //   closeOnClick: true,
                    constrainWidth: true,
                  },
                }}
                value={eventAgeLimit}
                onChange={(e) => {
                  // console.log(e.target.value);
                  setEventAgeLimit(e.target.value);
                }}
              >
                {ageLimitOptions}
              </Select>

              {mode === "create" ? (
                <div>
                  <div className="col s12 mt10 mb25">
                    Вы можете сразу создать несколько встреч с такими же
                    настройками в пределах 30-ти дневного горизонта
                    планирования. Просто выберите ниже тип повторения, и мы
                    создадим копии этой встречи в другие дни. Позднее вы сможете
                    отредактировать каждую отдельную встречу.
                  </div>

                  <Select
                    id="package-repetition"
                    name="package-repetition"
                    s={12}
                    l={12}
                    icon={<Icon>repeat</Icon>}
                    label={"Повторение встречи"}
                    options={{
                      classes: "",
                      dropdownOptions: {
                        alignment: "left",
                        //   closeOnClick: true,
                        constrainWidth: true,
                      },
                    }}
                  >
                    <option value="0">Не повторять</option>
                    <option value="1">Каждый день</option>
                    <option value="2">Каждую неделю</option>
                    <option value="3">Каждый будний день</option>
                  </Select>
                </div>
              ) : null}
            </div>
          ) : null}

          <div className="col s12 mt10 mb25">
            Если хотите создать встречу с бесплатным участием, то в качестве
            цены укажите 0 (нуль).
          </div>

          <TextInput
            id="package-price"
            name="package-price"
            icon="payment"
            s={12}
            l={6}
            label="Цена участия"
            placeholder="1000"
            inputMode="decimal"
            value={String(packagePrice)}
            onChange={(e) => {
              setPackagePrice(e.target.value);
            }}
            required
          />

          <Select
            id="package-currency"
            name="package-currency"
            s={12}
            l={6}
            icon={<Icon>currency_bitcoin</Icon>}
            label={"Валюта"}
            options={{
              classes: "",
              dropdownOptions: {
                alignment: "left",
                //   closeOnClick: true,
                constrainWidth: true,
              },
            }}
            value={packageCurrency}
            onChange={(e) => {
              // console.log(e.target.value);
              setPackageCurrency(e.target.value);
            }}
          >
            {currencyOptions}
          </Select>

          <Textarea
            id="package-description"
            name="package-description"
            label={"Подробное описание"}
            icon={<Icon>description</Icon>}
            placeholder={
              "Пакет участия для тех, кто впервые собирается прийти на наше мероприятие..."
            }
            s={12}
            value={packageDescription}
            onChange={(e) => {
              setPackageDescription(e.target.value);
            }}
            style={{ marginBottom: "0px" }}
          />

          {searchParams.get("project") ? null : (
            <div>
              <div className="col s12 mb20">
                <div className="switch">
                  <label style={{ fontSize: "100%" }}>
                    <input
                      checked={packagePspMode == 1 ? "checked" : ""}
                      type="checkbox"
                      id="package-is-psp-mode"
                      name="package-is-psp-mode"
                      onChange={(e) => {
                        e.target.checked
                          ? setPackagePspMode(1)
                          : setPackagePspMode(0);
                      }}
                    />
                    <span className="lever" style={{ margiLeft: "0px" }}></span>
                    <span className="tertiary-color">
                      Участники встречи видят список других участников
                    </span>
                  </label>
                </div>
              </div>

              <div className="col s12 mb20">
                <div className="switch">
                  <label style={{ fontSize: "100%" }}>
                    <input
                      checked={packagePaymentType == 1 ? "checked" : ""}
                      type="checkbox"
                      id="package-is-online-payment-ym"
                      name="package-is-online-payment-ym"
                      onChange={(e) => {
                        e.target.checked
                          ? setPackagePaymentType(1)
                          : setPackagePaymentType(0);
                      }}
                    />
                    <span className="lever" style={{ margiLeft: "0px" }}></span>
                    <span className="tertiary-color">
                      Принимать оплату онлайн (только для валюты ₽ (RUB)
                      «Российский рубль»)
                    </span>
                  </label>
                </div>
              </div>

              <div className="col s12 mb25">
                Все онлайн-платежи проходят через сервис{" "}
                <a
                  href="https://yoomoney.ru"
                  className="iq-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  ЮMoney
                </a>
                . Чтобы покрыть его комиссию и комиссии банков РФ при выводе вам
                денежных средств, мы вынуждены удерживать комиссию в размере 5%
                от каждой оплаты ваших вариантов участия (например, если вы
                установили цену на вариант участия в 100 ₽, то при выводе
                денежных средств мы отправим вам 100 ₽ - 5% = 95 ₽). Вы
                по-прежнему можете принимать пожертвования и оплаты
                самостоятельно, не принимая их через Nott.one. Однако мы
                рекомендуем использовать нашу систему оплаты — это просто,
                удобно и безопасно для пользователей, а также повышает уровень
                ваших мероприятий.
              </div>

              <TextInput
                id="package-participation-link"
                name="package-participation-link"
                icon="link"
                s={12}
                label="Ссылка на онлайн-трансляцию"
                placeholder="Необязательно"
                value={packageParticipationLink}
                onChange={(e) => {
                  setPackageParticipationLink(e.target.value);
                }}
              />

              <div className="col s12 mb10">
                Ссылка обязательно должна содержать протокол HTTPS (пример —
                https://yourstreamsite.com/path/to/stream)
              </div>
              <div className="col s12">
                Когда участник приобретет вариант участия (пакет участия), в его
                билете появится данная ссылка на онлайн-трансляцию. Для каждого
                пакета участия вы можете указывать собственную ссылку на
                трансляцию, что может быть полезно, чтобы ссылку видели только
                те участники, которые приобретили пакет онлайн-участия. Также вы
                можете в рамках одного мероприятия разделить онлайн-участников
                на секции по темам или комнаты для А/B-тестирования.
              </div>
            </div>
          )}

          <div className="col s12 center-align mt20">
            <Button flat className="iq-btn" type="submit">
              {mode === "edit" ? "Сохранить" : "Создать"}{" "}
              {searchParams.get("event") ? "встречу" : "вариант участия"}
            </Button>
          </div>
        </div>
      </form>
    </RegularPage>
  ) : null;
};

export default CreateEditPackage;
