import moment from "moment";
import React, { useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import noAvatar from "../../../images/no-avatar.png";
import useConfirm from "../../../context/ConfirmDialog";
import RequestService from "../../../api/RequestService";
import { AuthContext } from "../../../context";

const Posts = ({
  posts,
  isLoading,
  getPosts,
  isCurrentUser = 0,
  onSurface = true,
}) => {
  const confirm = useConfirm();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);

  const removePost = async (postId) => {
    const choice = await confirm({
      description: "Вы действительно хотите удалить запись?",
    });

    if (choice) {
      setFullScreenLoading(true);
      const response = (await RequestService.removePost(postId)).data;
      setFullScreenLoading(false);
      // console.log(response);
      if (!response.response_code) {
        await confirm({
          description: response.message,
          isAlert: true,
        });

        return;
      }

      await confirm({
        description: "Запись удалена",
        isAlert: true,
      });
      // alert("Событие удалено");

      getPosts();
    }
  };

  const failure = (
    <div>
      <div className="row nm mt15">
        <div className="col s12 center-align">
          Информации нет или при загрузке произошла ошибка
        </div>
      </div>
    </div>
  );
  if (!posts && !isLoading) {
    return failure;
  }
  if (posts && !isLoading) {
    if (posts.length == 0) {
      return failure;
    }
  }
  let flow = null;
  if (posts) {
    let postElements = [];
    for (let i = 0; i < posts.length; i++) {
      postElements.push(
        <div className="col s12" key={posts[i].id}>
          <div
            className={
              onSurface
                ? "card mb30 bg animation-appear"
                : "card mb30 animation-appear"
            }
          >
            <div className="card-content">
              <div className="row nm">
                <Link to={"/users/" + posts[i].alias}>
                  <div className="col s3 l1">
                    <div
                      className="circular-image pastel-shadow"
                      style={{
                        width: "45px",
                        height: "45px",
                      }}
                    >
                      <div
                        className={
                          posts[i].avatar_img_url
                            ? "circular-image"
                            : "circular-image image-theme-filter"
                        }
                        style={{
                          backgroundImage:
                            "url(" +
                            (posts[i].avatar_img_url
                              ? posts[i].avatar_img_url
                              : noAvatar) +
                            ")",
                          height: "45px",
                          width: "45px",
                        }}
                      ></div>
                    </div>
                  </div>
                </Link>
                <div className="col s9 l11">
                  <Link to={"/users/" + posts[i].alias}>
                    <div className="row nm">
                      <div className="col s12 m500">{posts[i].name}</div>
                      <div className="col s12 s300 mt5 tertiary-color">
                        {moment(posts[i].timestamp).format("DD.MM.YYYY HH:mm") + " (МСК)"}
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col s12 secondary-color mt15">
                  {posts[i].text}
                </div>

                {isCurrentUser ? (
                  <div className="col s12 right-align mt15">
                    <span
                      className="iq-link"
                      onClick={() => {
                        removePost(posts[i].id);
                      }}
                    >
                      <i
                        className="material-icons tiny accent-color"
                        style={{ position: "absolute", marginTop: "3px" }}
                      >
                        delete
                      </i>
                      <span style={{ marginLeft: "20px" }}>Удалить</span>
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      );
    }

    flow = postElements;
  } else {
    return (
      <div className="row nm">
        <div className="col s12 center-align">Ошибка</div>
      </div>
    );
  }

  return <div className="row nm">{flow}</div>;
};

export default Posts;
