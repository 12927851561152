import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import RequestService from "../api/RequestService";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { AuthContext } from "../context";
import useConfirm from "../context/ConfirmDialog";
import CircleLoader from "../components/UI/CircleLoader/CircleLoader";
import { useNavigate } from "react-router-dom";

const AuthVkid = () => {
  const [userData, setUserData] = useState(null);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { isAuth, setIsAuth } = useContext(AuthContext);

  const completeVkidAuth = async () => {
    const response = (
      await RequestService.completeVkidAuth(window.location.href)
    ).data;
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      navigate("/login");
      return;
    }
    setIsAuth(true);
    navigate("/events");
  };

  useEffect(() => {
    completeVkidAuth();
  }, []);

  return (
    <RegularPage
      isDataExist={1}
      hasBaseCard={true}
      header={"Устанавливаем связь с VK ID"}
      isPrimaryPage={false}
    >
      <div className="row mt25">
        <div className="col s12 secondary-color mb20">
          Эта страница обновится автоматически. Пожалуйста, подождите...
        </div>
        <div className="col s12">
          <CircleLoader />
        </div>
      </div>
    </RegularPage>
  );
};

export default AuthVkid;
