import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import RequestService from "../api/RequestService";
import { AuthContext } from "../context";
import LogoLight from "../images/logo-light.png";
import LogoDark from "../images/logo-dark.png";
import { applyTheme, getQueryParam, getTheme } from "../utils/service";
import { Icon, TextInput } from "react-materialize";
import { Link } from "react-router-dom";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import useConfirm from "../context/ConfirmDialog";
import * as VKID from "@vkid/sdk";

const Login = () => {
  const { isAuth, setIsAuth, isLoading, setLoading } = useContext(AuthContext);
  const [loginData, setLoginData] = useState({ login: "", password: "" });
  const [logoData, setLogoData] = useState(LogoLight);
  const navigate = useNavigate();
  const confirm = useConfirm();
  // console.log(loginData)

  async function login(e) {
    e.preventDefault();

    setLoading(true);
    let authRes = await RequestService.auth(
      loginData.login,
      loginData.password
    ); // getAuth('karmazin10@inbox.ru', 'X@tntxyiev2EVHK@');
    // console.log(authRes.data);
    setLoading(false);
    if (authRes.data.response_code == 1) {
      setIsAuth(true);
      navigate("/events");
    } else {
      await confirm({
        description: "Неверные данные",
        isAlert: true,
      });
      // alert("Неверные данные");
    }
  }

  useEffect(() => {
    VKID.Config.init({
      app: 52010578,
      codeChallenge: "llhsUeNbOvWtGlcDkmCJil9oONOUE_dmLUh_egkkjNk",
      redirectUrl: window.nott.info.vkid_auth_redirect_url,
      scope: "vkid.personal_info email",
      mode: VKID.ConfigAuthMode.Redirect,
    });

    let oneTap = new VKID.OneTap();
    let container = document.getElementById("VkIdSdkOneTap");

    if (container) {
      oneTap
        .render({
          container: container,
          scheme: VKID.Scheme.LIGHT,
          lang: VKID.Languages.RUS,
        })
        .on(VKID.WidgetEvents.ERROR, vkidHandleError);
    }
  }, []);

  const vkidHandleError = (e) => {
    console.log(e);
  };

  useEffect(() => {
    applyTheme();
    const theme = getTheme();
    if (theme === "dark") {
      setLogoData(LogoDark);
    } else {
      setLogoData(LogoLight);
    }
  }, []);

  return (
    <RegularPage isDataExist={1} hasBaseCard={false}>
      <div className="row nm">
        <div className="col l3"></div>
        <div className="col s12 l6">
          <div className="card">
            <div className="card-content">
              <div className="row nm xl500 center-align hide-on-med-and-down">
                <div className="col s12 center-align">
                  <img
                    src={logoData}
                    alt="Logo"
                    style={{ width: "25%", maxWidth: "200px" }}
                    className="mt15"
                  />
                </div>
              </div>
              <div className="row nm hide-on-large-only">
                <div className="col s12 xl500 center-align">Вход в аккаунт</div>
              </div>
              <div className="row nm mt20 hide-on-med-and-down">
                <div className="col s12 xl500 center-align">Вход в аккаунт</div>
              </div>
              <div className="row mt25">
                {(getQueryParam("after") === "signup" ||
                  getQueryParam("after") === "forgot") &&
                getQueryParam("email") ? (
                  <div
                    className="col s12 alert-message center-align"
                    style={{ borderRadius: "15px", padding: "15px" }}
                  >
                    Мы отправили письмо на{" "}
                    <span className="accent-color">
                      {getQueryParam("email")}
                    </span>
                    . Прочитайте его и скорее возвращайтесь. В редких случаях
                    письмо может идти до 20 минут. Проверяйте папку «Спам» и
                    «Рассылки»/«Реклама».
                  </div>
                ) : getQueryParam("after") === "recovery" ? (
                  <div
                    className="col s12 alert-message center-align"
                    style={{ borderRadius: "15px", padding: "15px" }}
                  >
                    Вы успешно изменили пароль. Авторизуйтесь с новыми данными.
                  </div>
                ) : (
                  <div className="col s12 center-align">
                    Создавайте события, проекты и присоединяйтесь к другим по
                    всему миру
                  </div>
                )}
              </div>
              <form onSubmit={login}>
                <div className="row nm">
                  <TextInput
                    type="email"
                    label="Email"
                    s={12}
                    icon={<Icon>alternate_email</Icon>}
                    onChange={(e) => {
                      setLoginData({ ...loginData, login: e.target.value });
                    }}
                    required
                  />

                  <TextInput
                    type="password"
                    label="Пароль"
                    s={12}
                    icon={<Icon>key</Icon>}
                    onChange={(e) => {
                      setLoginData({
                        ...loginData,
                        password: e.target.value,
                      });
                    }}
                    required
                  />

                  <div className="col s12 center-align mt15">
                    <input
                      type="submit"
                      className="btn iq-btn btn-flat"
                      value="Войти"
                    />
                  </div>
                </div>
              </form>

              <div className="row nm">
                <div className="col l2"></div>
                <div className="col s12 l8 mt25">
                  <div id="VkIdSdkOneTap"></div>
                </div>
                <div className="col l2"></div>
              </div>

              <div className="row nm center-align mt25">
                <div className="col s12 mb15">
                  <Link to="/forgot" className="iq-link">
                    Я забыл пароль
                  </Link>
                </div>
                <div className="col s12 secondary-color">
                  Еще не с нами?{" "}
                  <Link to="/signup" className="iq-link">
                    Зарегистрируйтесь
                  </Link>{" "}
                  прямо сейчас
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col l3"></div>
      </div>
    </RegularPage>
    // <div>
    //   <div className="container">

    //   </div>
    // </div>
  );
};

export default Login;
